export default {
  blue: '#326CE5',
  blueLight: '#AECBFA',
  blueDark: '#3B48A9',
  red: '#E55B2D',
  // redLight: "#FCE7EE",
  redLight: '#ED8E24',
  violet: '#3B48A9',
  green: '#00965E',
  greenLight: '#D0EBE1',
  yellow: '#FBBC05',
  orange: '#ED8E24',
  orangeLight: '#FBE9D0',
  white: '#FFFFFF',
  black: '#3C4043',
  gray: '#80868B',
  grayLight: '#BDC1C6',
  grayDark: '#5F6368',
  shadow: '#e5f3ff'
};
