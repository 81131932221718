import defaultColors from './colors';

const colors = {
  ...defaultColors,

  primary: defaultColors.blue,
  primaryDark: defaultColors.blueDark,
  font: defaultColors.black,
  fontDark: defaultColors.grayDark,
  background: '#F5F7F9',
  mainBackground: '#fefefe',
  border: defaultColors.grayLight,
  hover: defaultColors.blueLight,
  // shadow: defaultColors.blueDark - '33',
};


export default {
  colors: colors,
};
