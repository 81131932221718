module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-instagram-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-pinterest/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/grenes/git/cskylab-docs/.boogi/v1.0.1/src/templates/docs.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null,null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-mermaid","options":{"language":"mermaid","theme":"neutral","viewport":{"width":300,"height":300},"mermaidOptions":{}}},"gatsby-remark-graphviz",{"resolve":"/Users/grenes/git/cskylab-docs/.boogi/v1.0.1/plugins/gatsby-remark-sectionize-toc/index.js","options":{"maxDepth":3}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1050,"quality":75,"showCaptions":true,"disableBgImageOnAlpha":true,"withWebp":true}},"gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-jargon","options":{"jargon":{"csky":"<span><b>csky</b> - csky - Beyond the cloud you see the sky.</span> Private cloud in a box technologies based on Kubernetes","LVM":"<span><b>LVM</b> - Logical Volume Management</span> Applications need to persists data. In cskylab, pods that need to persist data are maped a Logical Volume."}}},{"resolve":"gatsby-remark-embed-snippet","options":{"directory":"/Users/grenes/git/cskylab-docs/.boogi/v1.0.1/snippets/"}},{"resolve":"gatsby-remark-embedder","options":{"customTransformers":[],"services":{}}}],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":null,"head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.cskylab.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/*"],"excludePaths":["/"],"height":6,"prependToBody":false,"color":"#FBBC05"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
