module.exports = {
    "metadata": {
        "name": "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        "short_name": "csdocs",
        "description": "cSkyLab documentation app",
        "language": "en",
        "url": "https://docs.cskylab.com",
        "pathPrefix": "/",
        "gaTrackingId": null,
        "siteImage": "/assets/logo_large.png",
        "favicon": "/assets/icon_color.svg",
        "themeColor": "#3B48A9"
    },
    "header": {
        "logo": "/assets/logo_small.svg",
        "logoLink": "https://www.cskylab.com",
        "helpUrl": "",
        "links": [],
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 10,
                "path": "/getting-started",
                "title": "Getting Started"
            },
            {
                "order": 20,
                "path": "/build-your-virtual-dc",
                "title": "Build your virtual data center"
            },
            {
                "order": 40,
                "path": "/reference",
                "title": "Reference"
            },
            {
                "order": 909,
                "path": "/license",
                "title": "License"
            }
        ],
        "links": [],
        "poweredBy": {}
    },
    "pwa": {
        "enabled": false,
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "fullscreen",
            "crossOrigin": "anonymous",
            "icon": "/assets/icon_color.svg"
        }
    },
    "social": {
        "facebook": "",
        "github": "https://github.com/cskylab/cskylab",
        "gitlab": "",
        "instagram": "",
        "linkedin": "",
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": "",
        "youtube": ""
    },
    "features": {
        "editOnRepo": {
            "editable": false,
            "location": "https://gitlab.trantor.es/gmazzotta/test-boogi-documents",
            "type": "gitlab"
        },
        "search": {
            "enabled": true,
            "indexName": "docs",
            "algoliaAppId": null,
            "algoliaSearchKey": null,
            "algoliaAdminKey": null,
            "excerptSize": 100000,
            "engine": "localsearch",
            "placeholder": "Search",
            "startComponent": "icon",
            "debounceTime": 380,
            "snippetLength": 22,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {},
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 3
        },
        "previousNext": {
            "enabled": false,
            "arrowKeyNavigation": false
        },
        "scrollTop": true,
        "showMetadata": true,
        "propagateNetlifyEnv": false,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 6,
            "prependToBody": false,
            "color": "#FBBC05",
            "includePaths": [
                "/*"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "neutral",
            "options": {},
            "width": 300,
            "height": 300
        },
        "rss": {
            "enabled": false,
            "showIcon": false,
            "title": "My RSS feed",
            "copyright": "2021 cskylab.com",
            "webMaster": "gmazzotta@trantor.es",
            "managingEditor": "grenes@trantor.es",
            "categories": [
                "cloud",
                "kubernetes",
                "development"
            ],
            "ttl": "60",
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "gidocs"
        },
        "darkMode": {
            "enabled": false,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": false,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        }
    }
};